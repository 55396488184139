"use client";

import { useStoryblokState } from "@storyblok/react";
import {
  ISbStoryData,
  StoryblokComponent,
  storyblokEditable,
} from "@storyblok/react/rsc";
import { track } from "@vercel/analytics";
import { clsx } from "clsx";
import Image from "next/image";
import Link from "next/link";
import { Fragment, useState } from "react";

import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import type {
  CampsiteStoryblok,
  ConfigStoryblok,
  MenuButtonStoryblok,
  MenuLinkStoryblok,
} from "@/component-types-sb";
import { H4 } from "@/components/typography";
import { useScrollDirection } from "@/lib/hooks/useScrollDirection";

export type Campsite = {
  id: number;
  full_slug: string;
  name: string;
  image: string;
};

type NavigationProps = {
  story: ISbStoryData<ConfigStoryblok>;
};

const Navigation = ({ story }: NavigationProps) => {
  // @ts-ignore
  story = useStoryblokState(story);

  const [selectedCampsite, setSelectedCampsite] =
    useState<CampsiteStoryblok | null>(
      (story.content?.campsites as unknown as CampsiteStoryblok[])?.[0] || null
    );

  const scrollDirection = useScrollDirection();

  return (
    <nav
      id="navigation"
      className={clsx(
        "fixed w-full top-[var(--header-top)] bg-white flex items-center justify-between shadow-sm max-w-8xl h-14 lg:h-20 transition-all duration-300 ease-in-out z-40",
        scrollDirection === "down"
          ? "translate-y-[calc(-1*112px_-_var(--header-top))]"
          : "translate-y-0"
      )}
      aria-label="Menu"
      {...storyblokEditable(story.content)}
    >
      <Link
        href="/"
        className="ml-4 lg:ml-24 translate-y-2 flex-1 lg:flex-none"
      >
        <Image
          src="/logo.svg"
          alt="KNAUS Campingparks"
          className="bg-white block w-36 lg:w-52 h-auto shadow-sm lg:shadow-md transition-all duration-300"
          width={415}
          height={180}
          priority
        />
      </Link>

      <div className="flex items-center gap-1 lg:order-2 lg:mr-2">
        <Link href="/search">
          <MagnifyingGlassIcon className="w-6 h-6" />
        </Link>

        {story.content?.button?.map((nestedBlok: MenuButtonStoryblok) => (
          <StoryblokComponent
            blok={nestedBlok}
            key={nestedBlok._uid}
            className="lg:order-2 lg:mr-2"
            onClick={() => {
              track("Booking click");
            }}
          />
        ))}
      </div>

      {/* Mobile Menu */}
      <Popover className="lg:hidden relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className="p-2.5 mr-1"
              aria-label="Menu ein-/ausblenden"
            >
              {open ? (
                <XMarkIcon className="h-6 w-6" />
              ) : (
                <Bars3Icon className="h-6 w-6" />
              )}
            </Popover.Button>

            <Popover.Panel
              className={clsx(
                "absolute right-0 top-10 p-4 w-screen origin-top-right bg-gray-50 shadow-md ring-1 ring-black/5 focus:outline-none -z-10",
                scrollDirection === "down" ? "hidden" : "block"
              )}
            >
              <div className="grid gap-2 pt-4">
                <Link
                  href="/campingparks"
                  className="text-sm font-bold text-gray-900 hover:bg-gray-100 p-1"
                  onClick={close}
                >
                  Auswahl Campingparks
                </Link>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-2 mx-2">
                  {story.content?.campsites?.map((campsite: any) => (
                    <Link
                      key={`mobile-${campsite.id}`}
                      href={`/${campsite.full_slug}`}
                      onClick={close}
                      className="text-sm font-light text-gray-900 hover:bg-gray-100 p-1 bg-white/90"
                    >
                      {campsite.name}
                    </Link>
                  ))}
                </div>
                <Link
                  href="/campingparks"
                  className="inline-flex items-center text-sm font-bold text-gray-900 hover:bg-gray-100 p-1"
                  onClick={close}
                >
                  <ChevronRightIcon className="h-4 w-4" />
                  Alle Campingparks
                </Link>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-2 p-1">
                  {story.content?.menu?.map((nestedBlok: MenuLinkStoryblok) => (
                    <StoryblokComponent
                      blok={nestedBlok}
                      key={`mobile-${nestedBlok._uid}`}
                      submenuStyle="disclosure"
                    />
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>

      {/* Desktop Menu */}
      <Popover.Group className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1 md:gap-4 xl:gap-8">
        <Popover className="relative">
          <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 hover:text-brand-primary100">
            Campingparks
            <ChevronDownIcon
              className="h-5 w-5 flex-none text-gray-400"
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute -left-8 top-full z-30 mt-3 w-screen max-w-[600px] overflow-hidden bg-white shadow-lg ring-1 ring-gray-900/5">
              {({ close }) => (
                <>
                  <div className="p-8 grid md:grid-cols-2 gap-2">
                    <div>
                      <div className="font-extrabold px-2 pb-2 text-black">
                        Auswahl Campingparks
                      </div>
                      <div className="grid grid-cols-2 gap-2">
                        {story.content?.campsites?.map((campsite: any) => (
                          <Link
                            key={campsite.id}
                            href={`/${campsite.full_slug}`}
                            onClick={() => close()}
                            onMouseOver={() => {
                              setSelectedCampsite(campsite);
                            }}
                            className="text-sm font-semibold text-gray-900 px-2 leading-6 bg-white hover:bg-gray-100 whitespace-nowrap text-ellipsis overflow-hidden"
                          >
                            {campsite.name}
                          </Link>
                        ))}
                        <Link
                          href="/campingparks"
                          onClick={() => close()}
                          className="col-span-2 text-sm font-semibold text-brand-primary100 leading-6 uppercase bg-white hover:bg-gray-100 whitespace-nowrap text-ellipsis overflow-hidden inline-flex items-center gap-1"
                        >
                          <ChevronRightIcon className="h-4 w-4" />
                          Alle Campingparks
                        </Link>
                      </div>
                    </div>
                    {selectedCampsite && (
                      <Link
                        href={`/${selectedCampsite.full_slug}`}
                        onClick={() => close()}
                        className="w-full h-48  relative bg-gradient-to-t from-black/50 to-50% to-transparent"
                      >
                        <Image
                          className="h-full w-full object-cover transition duration-500 aspect-square mix-blend-multiply"
                          src={selectedCampsite.content?.image.filename}
                          width={268}
                          height={268}
                          alt={selectedCampsite.name || ""}
                        />
                        <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                          <H4
                            colorScheme="white"
                            fontWeight="extrabold"
                            fontSize="2xl"
                          >
                            {selectedCampsite.name}
                          </H4>
                        </div>
                      </Link>
                    )}
                  </div>
                  <div className="grid grid-cols-1 divide-x divide-gray-900/5 bg-gray-50">
                    {/* <Link
                      href="/hotel-k1-nohra"
                      onClick={() => {
                        close();
                      }}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold uppercase leading-6 text-brand-primary100 hover:bg-gray-100"
                    >
                      <BuildingOfficeIcon className="h-4 w-4" />
                      Hotel K1 Nohra
                    </Link> */}
                    <Link
                      href="/buchen"
                      onClick={() => {
                        track("Booking click");
                        close();
                      }}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold uppercase leading-6 text-brand-primary100 hover:bg-gray-100"
                    >
                      Buchen
                    </Link>
                  </div>
                </>
              )}
            </Popover.Panel>
          </Transition>
        </Popover>

        {story.content?.menu?.map((nestedBlok: MenuLinkStoryblok) => (
          <StoryblokComponent
            blok={nestedBlok}
            key={`desktop-${nestedBlok._uid}`}
          />
        ))}
      </Popover.Group>
    </nav>
  );
};

export { Navigation };
