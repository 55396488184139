import { MapPinIcon, PhotoIcon } from "@heroicons/react/20/solid";
import { storyblokEditable } from "@storyblok/react/rsc";
import { clsx } from "clsx";
import dynamic from "next/dynamic";
import { useEffect, useReducer, useState } from "react";

import type {
  CampsiteOverviewStoryblok,
  CampsiteStoryblok,
} from "@/component-types-sb";
import { Card } from "@/components/layout/Card";
import { BodyText, Divider, H2 } from "@/components/typography";

const Map = dynamic(() =>
  import("@/components/layout/Map").then((module) => module.Map)
);

type CampsiteOverviewProps = {
  blok: CampsiteOverviewStoryblok;
};

const reducer = (state: any, action: { type: "card" | "map" }) => {
  return { ...state, ...action };
};

const CampsiteOverview = ({ blok }: CampsiteOverviewProps) => {
  const [state, dispatch] = useReducer(reducer, { type: "card" });
  const [campsites, setCampsites] = useState<CampsiteStoryblok[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch("/api/campsites");
      const json = await res.json();
      setCampsites(json);
    };
    fetchData();
  }, []);

  const hasOffer = (current: CampsiteStoryblok) =>
    campsites.some(
      (campsite) => campsite.uuid == current.uuid && campsite.offers > 0
    );

  return (
    <article className="w-full" {...storyblokEditable(blok)}>
      <section className="py-8 mx-2 lg:mx-4 text-center">
        <H2 as="h1" colorScheme="primary">
          {blok.title}
        </H2>
        <BodyText className="max-w-md mx-auto mt-2 text-center">
          {blok.description}
        </BodyText>

        <Divider />

        <div className="inline-flex shadow-sm mb-8" role="group">
          <button
            type="button"
            className={clsx(
              "inline-flex gap-2 items-center px-4 py-2 text-sm font-medium bg-white border border-r-0 border-gray-200 hover:bg-gray-100 hover:text-brand-primary100 focus:z-10 focus:ring-2 focus:ring-brand-primary100 focus:text-brand-primary100",
              {
                "text-gray-500": state.type !== "card",
                "text-brand-primary100": state.type === "card",
              }
            )}
            onClick={() => dispatch({ type: "card" })}
          >
            <PhotoIcon className="h-5 w-5" aria-hidden="true" />
            Bildansicht
          </button>
          <button
            type="button"
            className={clsx(
              "inline-flex gap-2 items-center px-4 py-2 text-sm font-medium bg-white border border-gray-200 hover:bg-gray-100 hover:text-brand-primary100 focus:z-10 focus:ring-2 focus:ring-brand-primary100 focus:text-brand-primary100",
              {
                "text-gray-500": state.type !== "map",
                "text-brand-primary100": state.type === "map",
              }
            )}
            onClick={() => dispatch({ type: "map" })}
          >
            <MapPinIcon className="h-5 w-5" aria-hidden="true" />
            Kartenansicht
          </button>
        </div>

        {state.type === "map" && (
          <div className="h-[1024px]">
            <Map campsites={blok.campsites as unknown as CampsiteStoryblok[]} />
          </div>
        )}

        {state.type === "card" && (
          <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
            {blok.campsites?.map((nestedBlok: any) => (
              <Card
                key={nestedBlok.id}
                image={nestedBlok.content?.image}
                href={nestedBlok.full_slug}
                name={nestedBlok.name}
                text={nestedBlok.content?.lead}
                isNew={nestedBlok.content.is_new}
                buttonLabel="Entdecken"
                hasOffer={hasOffer(nestedBlok)}
              />
            ))}
          </div>
        )}
      </section>
    </article>
  );
};

export default CampsiteOverview;
